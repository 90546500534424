/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import QuickClose from './QuickClose';
import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';

import '../scss/index.scss';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <div className="site-content">
          <QuickClose />
          <SiteHeader siteTitle={data.site.siteMetadata.title} />
          <main>{children}</main>
          <SiteFooter />
        </div>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
