import React from 'react';

const QuickClose = () => (
  <div className="quick-close">
    <a
      href="https://www.google.co.uk/"
      target="_self"
      className="quick-close__link"
    >
      <span className="quick-close__text">Quick Close</span>
      <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="nonzero">
          <path d="M.63 3.929l3.299-3.3L21.37 18.071l-3.3 3.3z" />
          <path d="M3.929 21.37l-3.3-3.299L18.071.63l3.3 3.3z" />
        </g>
      </svg>
    </a>
  </div>
);

export default QuickClose;
